import React, { useContext, useEffect, useState } from "react";
import { Progress, Space, Spin, Table, Tag, Typography } from "antd";

import DashboardBackground from "../components/Dashboard/DashboardBackground";
import CustomSearch from "../components/CustomSearch";
import CustomButton from "../components/CustomButton";
import AssessmentCard from "../components/Assessment/AssessmentCard";
import { assessmentCardList } from "../utils/assessmentCardList";
import SelectTypeModal from "../components/Assessment/SelectTypeModal";
import ScheduleAssessmentModal from "../components/Assessment/ScheduleAssessmentModal";
import AssessmentSuccessModal from "../components/Assessment/AssessmentSuccessModal";
import assessmentApi from "../api/assessmentAxios";
import { notificationsContext } from "../context/Notifications";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { AssessmentStatus } from "../utils/constants";

const { Title } = Typography;

const Assessment = () => {
  const { api: notificationAPI } = useContext(notificationsContext);
  const navigate = useNavigate();

  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [scheduleAssessmentValues, setScheduleAssessmentValues] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen]=useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [assessmentStats, setAssessmentStats] = useState(assessmentCardList);

  useEffect(() => {
    (async () => await getAssessments())();
  }, [refreshTable, searchText]);

  const getAssessments = async (
    currentPage = 1,
    pageSize = 10,
    sort_by,
    sort_order
  ) => {
    try {
      setIsLoading(true);
      const params = {
        page_number: currentPage,
        page_limit: pageSize,
        sort_by: sort_by,
        sort_order:sort_order
      };

      if (searchText.length > 0) params.search_text = searchText;

      const assessments = await assessmentApi.get("/assessment", { params });
      setData(assessments?.data?.data?.rows);

      // Update assessmentStats by mapping through initial state and setting count based on API data
      const updatedStats = assessmentCardList.map((stat) => {
        const apiStat = assessments?.data?.data?.assessmentStats.find((item) => item.status === stat.status);
        return {
          ...stat,
          count: apiStat ? apiStat.count : 0, // Keep bgColor, update count if found in API data
        };
      });
      setAssessmentStats(updatedStats);
      setTableParams({
        pagination: {
          current: currentPage,
          pageSize,
          total: assessments?.data?.data?.count,
        },
      });
      setIsLoading(false);
    } catch (err) {
         if (err?.response?.data?.token_expire === true) {
           setIsLoading(false);
           setIsTokenExpiredModalOpen(true);
         } else {
           setIsLoading(false);
           notificationAPI.error({
             message: "Error",
             description: err?.message,
             placement: "top",
             duration: 4,
           });
         }
    }
  };

  const handleTableChange = async (pagination,filters, sorter) => {
    const sort_by = sorter.field; // Get the field to sort by
    const sortOrder = sorter.order;
    const sort_order =
      sortOrder === "ascend"
        ? "asc"
        : sortOrder === "descend"
        ? "desc"
        : undefined;
    setTableParams({
      pagination,
    });
    await getAssessments(pagination.current, pagination.pageSize, sort_by, sort_order);
  };

  const showTypeModal = () => {
    setIsTypeModalOpen(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
      // sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      key: "assigned_to",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (due_date) => {
        const date = new Date(due_date);
        const formattedDate = date.toLocaleDateString("en-GB");
        return formattedDate;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => {
        let color = "";

        status === AssessmentStatus.NOT_YET_STARTED
          ? (color = "yellow")
          : status === AssessmentStatus.IN_PROGRESS
          ? (color = "red")
          : status === AssessmentStatus.REVIEWING
          ? (color = "purple")
          : status === AssessmentStatus.COMPLETED
          ? (color = "green")
          : (color = "gold");

        return (
          <Tag
            color={color}
            key={status}
            className="rounded-2xl font-semibold text-sm"
            bordered={false}
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (_, { score }) => <Progress percent={score} size="small" />,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div className="w-full flex justify-center">
          <div className="w-14">
            <CustomButton
              text="View"
              variant="outlined"
              className="h-7"
              disabled={record.status === AssessmentStatus.COMPLETED}
              onClick={() =>
                navigate(
                  `/dashboard/assessment/assessment-detail/${record?.standard_id}?standard=${record.type}&assessment=${record.id}&status=${record.status}`
                )
              }
            />
          </div>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center">
        <CustomSearch setSearchText={setSearchText} />
        <div className="w-48">
          <CustomButton
            text="Schedule Assessment"
            type="primary"
            className="h-9"
            onClick={showTypeModal}
          />
        </div>
        <SelectTypeModal
          isTypeModalOpen={isTypeModalOpen}
          setIsTypeModalOpen={setIsTypeModalOpen}
          setSelectedType={setSelectedType}
          showTypeModal={showTypeModal}
          setIsScheduleModalOpen={setIsScheduleModalOpen}
        />
        <ScheduleAssessmentModal
          isScheduleModalOpen={isScheduleModalOpen}
          setIsScheduleModalOpen={setIsScheduleModalOpen}
          selectedType={selectedType}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
          setScheduleAssessmentValues={setScheduleAssessmentValues}
          setRefreshTable={setRefreshTable}
        />
        <AssessmentSuccessModal
          isSuccessModalOpen={isSuccessModalOpen}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
        />
        <TokenExpireModal
          isTokenExpiredModalOpen={isTokenExpiredModalOpen}
          setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
        />
      </div>

      <div className="h-[80vh] m-5 bg-white px-5 pt-5 overflow-y-scroll">
        <Title level={3}>Assessment</Title>
        <div className="flex gap-5 w-full">
          {assessmentStats.map((card, index) => (
            <AssessmentCard
              key={index}
              bgColor={card.bgColor}
              title={card.status}
              value={card.count}
            />
          ))}
        </div>

        <div className="mt-6">
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              ...tableParams.pagination,
              position: ["bottomCenter"],
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </DashboardBackground>
  );
};

export default Assessment;
