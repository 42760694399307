import React, { useState } from "react";
import { Typography, Form, Input, Button } from "antd";

import CustomCard from "./CustomCard";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";

const { Title, Text, Link } = Typography;

const SignInCode = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    const value = element.target.value.replace(/[^0-9]/g, "");
    if (value.length > 1) return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Auto focus to the next input
    if (value && index < 5) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  const handleSubmit = () => {
    navigate("/dashboard/");
  };

  return (
    <CustomCard>
      <Title level={2} className="!font-bold">
        We have sent you a code
      </Title>

      <Text type="secondary">The code was sent to +1234567890</Text>

      <div className="flex justify-center space-x-3 my-10">
        {code.map((digit, idx) => (
          <Input
            key={idx}
            id={`code-${idx}`}
            className="w-10 h-10 text-center text-lg font-medium border border-gray-300"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(e, idx)}
          />
        ))}
      </div>

      <CustomButton text="Next" type="primary" onClick={handleSubmit} />

      <div className="flex flex-col items-center w-full mt-8 gap-1">
        <Text type="secondary">Didn’t receive the code?</Text>
        <Link className="!underline !text-appBlue">Resend code</Link>
      </div>
    </CustomCard>
  );
};

export default SignInCode;
