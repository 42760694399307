import React, { useContext, useState } from "react";
import {
  Divider,
  Form,
  Input,
  Modal,
  Typography,
  Select,
  DatePicker,
  Spin,
} from "antd";
import CustomButton from "../CustomButton";
import assessmentApi from "../../api/assessmentAxios";
import { notificationsContext } from "../../context/Notifications";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import { AssessmentStatus } from "../../utils/constants";

const { Title, Text } = Typography;
const { Option } = Select;

const ScheduleAssessmentModal = ({
  setIsScheduleModalOpen,
  isScheduleModalOpen,
  selectedType,
  setIsSuccessModalOpen,
  setScheduleAssessmentValues,
  setRefreshTable,
}) => {
  const [form] = Form.useForm();
  const { Item } = Form;

  const { api: notificationAPI } = useContext(notificationsContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setIsScheduleModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const newDate = new Date(values.due_date);

      const formData = {
        ...values,
        due_date: newDate.toISOString(),
        status: AssessmentStatus.NOT_YET_STARTED,
        type: selectedType?.name,
        standard_id: selectedType?.id,
      };

      await assessmentApi.post("/assessment", formData);

      setScheduleAssessmentValues(formData);
      form.resetFields();
      setIsLoading(false);
      setIsScheduleModalOpen(false);
      setIsSuccessModalOpen(true);
      setRefreshTable((prev) => !prev);
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err.message,
        placement: "top",
        duration: 4,
      });
    }
  };

  // Disable past dates in DatePicker
  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of the current day
    return current && current.valueOf() < today.getTime(); // Disable dates before today
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  return (
    <>
      <Modal
        title={
          <div className="w-full items-center flex justify-between">
            <Title level={4}>Schedule New Assessment</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isScheduleModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={450}
      >
        <Divider className="m-0" />
        <div className="my-5 flex justify-between">
          <Title level={5}>Assessment Type</Title>
          <Text>{selectedType?.name}</Text>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={customizeRequiredMark}
        >
          <Item
            label="Assessment Name"
            className="font-roboto"
            name="name"
            rules={[
              { required: true, message: "Please enter a Assessment Name" },
            ]}
          >
            <Input placeholder="Name" size="large" className="font-normal" />
          </Item>

          {/* New Select for Assigned To */}
          <Item
            label="Assigned To"
            className="font-roboto"
            name="assigned_to"
            rules={[{ required: true, message: "Please select a person" }]}
          >
            <Select placeholder="Select a person" size="large">
              <Option value="person1">Person 1</Option>
              <Option value="person2">Person 2</Option>
              <Option value="person3">Person 3</Option>
            </Select>
          </Item>

          {/* New Select for Primary Contact */}
          <Item
            label="Primary Contact"
            className="font-roboto"
            name="primary_contact"
            rules={[{ required: true, message: "Please select a contact" }]}
          >
            <Select placeholder="Select a contact" size="large">
              <Option value="contact1">Contact 1</Option>
              <Option value="contact2">Contact 2</Option>
              <Option value="contact3">Contact 3</Option>
            </Select>
          </Item>

          {/* New DatePicker for scheduling with future dates */}
          <Item
            label="Due Date (MM/DD/YYYY)"
            className="font-roboto"
            name="due_date"
            rules={[{ required: true, message: "Please enter a valid date" }]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              format="MM/DD/YYYY"
              className="font-normal"
            />
          </Item>

          <Item>
            <CustomButton
              text={isLoading ? <Spin /> : "Schedule"}
              type="primary"
              htmlType="submit"
              className="h-11 mt-4"
              disabled={isLoading}
            />
          </Item>
        </Form>

        <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default ScheduleAssessmentModal;
