import React, { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Table, Typography, Spin, Card } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import newAsset from "../api/assetAxios";
import CustomButton from "../components/CustomButton";
const { Title } = Typography;
const AssetViewDetail = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [data, setData] = useState();
  const [mapData, setMapData] = useState();
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => fetchData())();
  }, [id]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await newAsset.get(`/asset/${id}`);
      setIsLoading(false);
      setData(res?.data?.data);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };


  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center">
        <div className="w-32">
        <CustomButton
          type="primary"
          text={"Back"}
          className="w-32"
          onClick={() => navigate(`/dashboard/assets`)}
        />
        </div>
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5 overflow-scroll overflow-x-hidden">
        <Title level={3}>{data?.name}</Title>
        <div className="mt-6">
          {isLoading ? (
            <Spin className="flex justify-center items-center" />
          ) : (
            <>
              <Card>
                <div className="flex w-full justify-between">
                  <div style={{ width: "47%" }}>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">
                        IP ({data?.ip?.length})
                      </div>
                      <div className="flex flex-col">
                        {data?.ip?.length > 0
                          ? data.ip.map((ip, index) => (
                              <div key={index}>{ip}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Roles</div>
                      <div className="flex flex-col">
                        {data?.roles?.length > 0
                          ? data.roles.map((role, index) => (
                              <div key={index}>{role}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-7 ">
                      <div className="font-semibold">Vendor</div>
                      <div>{data?.vendor}</div>
                    </div>
                  </div>
                  <div style={{ width: "47%" }}>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Mac Address</div>
                      <div className="flex flex-col">
                        {data?.mac_address?.length > 0
                          ? data.mac_address.map((mac, index) => (
                              <div key={index}>{mac}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto ">
                      <div className="font-semibold">Mac Vendor</div>
                      <div className="flex flex-col">
                        {data?.mac_vendor?.length > 0
                          ? data.mac_vendor.map((vendor, index) => (
                              <div key={index}>{vendor}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <div className="flex justify-between mt-3">
                <Card style={{ width: "49%" }}>
                  <div style={{ width: "100%" }}>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Locations</div>
                      <div className="flex flex-col">{data?.location}</div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Nodes:</div>
                      <div className="flex flex-col">
                        {data?.nodes?.length > 0
                          ? data.nodes.map((node, index) => (
                              <div key={index}>{node}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto ">
                      <div className="font-semibold">Nodes UIDs:</div>
                      <div className="flex flex-col">
                        {data?.nodes_uids?.length > 0
                          ? data.nodes_uids.map((node, index) => (
                              <div key={index}>{node}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                  </div>
                </Card>
                <Card style={{ width: "49%" }}>
                  <div style={{ width: "100%" }}>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Device Id:</div>
                      <div className="flex flex-col">{data?.device_id}</div>
                    </div>
                    {/* <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Properties</div>
                      <div className="flex flex-col">
                        {Object.entries(data?.properties).map(
                          ([ip, details]) => (
                            <div key={ip}>
                              <h4>IP: {ip}</h4>
                              <p>Type: {details["_type.asset-kb"]}</p>
                              <p>Vendor: {details["_vendor.passive"]}</p>
                              <p>
                                Product Name:{" "}
                                {details["_product_name.asset-kb"]}
                              </p>
                              <p>
                                Notified Info:{" "}
                                {details["_notified_va_info.asset-kb"]}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    </div> */}
                    <div className="flex border-b border-gray-100 justify-between align-center h-7 ">
                      <div className="font-semibold">End of support date:</div>
                      <div className="flex flex-col">
                        {data?.end_of_support_date}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-7 ">
                      <div className="font-semibold">End of sale date:</div>
                      <div className="flex flex-col">
                        {data?.end_of_sale_date}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <Card className="w-full mt-3 mb-3">
                <div className="flex flex-col border-b border-gray-100 justify-between align-center h-auto mb-3">
                  <div className="font-semibold">Properties</div>
                  <div className="w-full flex flex-row justify-between mt-4">
                    {data?.properties &&
                    Object.keys(data.properties).length > 0 ? (
                      Object.entries(data.properties).map(([ip, details]) => (
                        <div style={{ width: "30%" }} key={ip}>
                          <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                            <div className="font-semibold">IP:</div>
                            <div className="flex flex-col">{ip}</div>
                          </div>
                          <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                            <div className="font-semibold">Type:</div>
                            <div className="flex flex-col">
                              {details["_type.asset-kb"]}
                            </div>
                          </div>
                          <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                            <div className="font-semibold">Vendor:</div>
                            <div className="flex flex-col">
                              {details["_vendor.passive"]}
                            </div>
                          </div>
                          <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                            <div className="font-semibold">Product Name:</div>
                            <div className="flex flex-col">
                              {details["_product_name.asset-kb"]}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No properties available.</div>
                    )}
                  </div>
                </div>
              </Card>
            </>
          )}
        </div>
      </div>
      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
    </DashboardBackground>
  );
};

export default AssetViewDetail;
