import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin, Steps, Typography } from "antd";
import {
  AssessmentDetailBreadCrumbList,
  AssessmentStatus,
} from "../utils/constants";
import CustomButton from "../components/CustomButton";
import Introduction from "../components/AssessmentSteps/Introduction";
import assessmentApi from "../api/assessmentAxios";
import { useParams, useSearchParams } from "react-router-dom";
import QuestionsWrapper from "../components/AssessmentSteps/QuestionsWrapper";
import { useContext } from "react";
import { notificationsContext } from "../context/Notifications";
import TokenExpireModal from "../components/TokenExpireModal";
import AssessmentSubmitModal from "../components/Assessment/AssessmentSubmitModal";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";

const AssessmentDetail = () => {
  const { Title } = Typography;
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { api: notificationAPI } = useContext(notificationsContext);
  const standardName = searchParams.get("standard");
  const assessmentId = +searchParams.get("assessment");
  const [assessmentCurrentStatus, setAssessmentCurrrentStatus] = useState(searchParams.get("status"))
  const [steps, setSteps] = useState(["Introduction"]);
  const [currentStep, setCurrentStep] = useState(0);
  const [introduction, setIntroduction] = useState({});
  const [questionnaire, setQuestionnaire] = useState({});
  const [isloading, setIsLoading] = useState(true);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [updatedAnswers, setUpdatedAnswers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitWarningModalOpen, setIsSubmitWarningModalOpen] =
    useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(false);

  useEffect(() => {
    (async () => await getStandardDetail())();
  }, []);

  const getStandardDetail = async () => {
    try {
      setIsLoading(true);
      const standardDetail = await assessmentApi.get(`/standard/${id}`);

      const questionnaire = await assessmentApi.get(
        `/questionnaire/standard/${id}?assessment_id=${assessmentId}`
      );

      const newSteps = [
        "Introduction",
        ...questionnaire?.data?.data?.categories,
      ];

      setSteps(newSteps);
      setIntroduction(standardDetail?.data?.data);
      setQuestionnaire(questionnaire?.data?.data?.questionnaire);
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const handleNext = async () => {
    if (
      currentStep == 0 &&
      assessmentCurrentStatus == AssessmentStatus.NOT_YET_STARTED
    ) {
      try {
        setIsLoading(true)
        await assessmentApi.put(
          `/assessment/${assessmentId}`,
          {
            status: AssessmentStatus.IN_PROGRESS
          }
        );
        setAssessmentCurrrentStatus(AssessmentStatus.IN_PROGRESS)
        setCurrentStep((prev) => prev + 1);
      } catch (err) {
        if (err?.response?.data?.token_expire === true) {
          setIsLoading(false);
          setIsTokenExpiredModalOpen(true);
        } else {
          setIsLoading(false);
          notificationAPI.error({
            message: "Error",
            description: err?.message,
            placement: "top",
            duration: 4,
          });
        }
      }
    } else if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      setIsSubmitWarningModalOpen(true)
    }
  };

  const submitAssessmentConfirmation = async () => {
    try {
      setIsSubmitWarningModalOpen(false);
      await assessmentApi.put(`/assessment/${assessmentId}`, {
        status: AssessmentStatus.COMPLETED,
      });
      setIsSubmitSuccessModalOpen(true);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsTokenExpiredModalOpen(true);
      } else {
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const handleSaveQuestionnaire = async () => {
    try {
      setIsSaving(true);

      await assessmentApi.post(
        "/assessment/bulk-save-assessment-response",
        updatedAnswers
      );

      notificationAPI.success({
        message: "Success",
        description: "Assessment Saved Successfully",
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
    setIsSaving(false);
  };

  return (
    <div className="p-5">
      <Breadcrumb items={AssessmentDetailBreadCrumbList} />

      <div className="flex justify-between items-center mt-3">
        <Title level={4} className="!m-0">
          {standardName} Assessment
        </Title>
        <div className="w-40">
          <CustomButton
            text={
              currentStep === 0 &&
              assessmentCurrentStatus == AssessmentStatus.NOT_YET_STARTED
                ? "Start Assessment"
                : currentStep < steps.length - 1
                ? "Next"
                : "Submit Assessment"
            }
            variant="outlined"
            className={`${
              currentStep < steps.length - 1 ? "rounded-lg" : "rounded-2xl"
            } h-9`}
            onClick={handleNext}
          />
        </div>
      </div>

      <div className="h-[78vh] mt-5 bg-[#EFF6FF] rounded-3xl">
        <div id="Steps" className="p-8 bg-appLightBlue rounded-3xl">
          <Steps
            progressDot
            current={currentStep}
            items={steps.map(
              (step) => (
                { title: "Introduction" },
                {
                  title:
                    step.length > 20 ? step.substring(0, 30) + "..." : step,
                  disabled: assessmentCurrentStatus == AssessmentStatus.NOT_YET_STARTED
                }
              )
            )}
            onChange={(val) => setCurrentStep(val)}
            className="overflow-x-auto flex flex-wrap"
          />
        </div>

        <div className="m-6">
          {currentStep === 0 ? (
            isloading ? (
              <Spin size="large" className="w-full" />
            ) : (
              <Introduction introduction={introduction} />
            )
          ) : (
            <QuestionsWrapper
              name={steps[currentStep]}
              data={questionnaire[steps[currentStep]]}
              setUpdatedAnswers={setUpdatedAnswers}
              handleSaveQuestionnaire={handleSaveQuestionnaire}
              assessmentId={assessmentId}
              isSaving={isSaving}
              stepsCount={steps.length}
              currentStep={currentStep}
            />
          )}
        </div>
      </div>
      <AssessmentSubmitModal
        isModalOpen={isSubmitWarningModalOpen}
        setIsModalOpen={setIsSubmitWarningModalOpen}
        title="Are you sure to submit the assessment?"
        icon={<WarningOutlined className="text-yellow-500 text-2xl" />}
        bodyText="Once you submit the assessment, You won't be able to edit it."
        buttons={[
          {
            text: "Submit",
            type: "primary",
            onClick: () => submitAssessmentConfirmation(),
          },
          {
            text: "Close",
            type: "primary",
            onClick: () => setIsSubmitWarningModalOpen(false),
          },
        ]}
      />
      <AssessmentSubmitModal
        isModalOpen={isSubmitSuccessModalOpen}
        setIsModalOpen={setIsSubmitSuccessModalOpen}
        title="Assessment Submitted"
        icon={<CheckCircleOutlined className="text-green-500 text-2xl" />}
        bodyText="Your Assessment is submitted successfully."
        buttons={[
          {
            text: "Close",
            type: "primary",
            onClick: () => setIsSubmitSuccessModalOpen(false),
          },
        ]}
      />
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </div>
  );
};

export default AssessmentDetail;
