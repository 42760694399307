import React, { useEffect, useState } from "react";
import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Typography,
  Upload,
  Input,
  Tooltip,
  Select,
} from "antd";
import CustomButton from "../CustomButton";
import { MAX_FILE_SIZE, QuestionStatus } from "../../utils/constants";
import assessmentApi from "../../api/assessmentAxios";

const { Title, Text } = Typography;
const { TextArea } = Input;

const Questions = ({ question, setUpdatedAnswers, assessmentId }) => {
  const [answers, setAnswers] = useState({
    [question.id]: question?.answer_data?.answer,
  });
  const [fileLists, setFileLists] = useState({
    [question.id]: question?.answer_data?.attachments || [],
  });
  const [isEditing, setIsEditing] = useState(question.answer_data === null);
  const [warningMessages, setWarningMessages] = useState({});
  const [questionStatus, setQuestionStatus] = useState({
    [question.id]:
      question.answer_data == null
        ? null
        : question.answer_data.status != null
        ? question.answer_data.status
        : null,
  });

  const fileTypes = [
    "application/pdf", // PDF files
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (DOCX)
    "application/msword", // Word (DOC)
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (XLSX)
    "application/vnd.ms-excel", // Excel (XLS)
    "text/csv", // CSV files
  ];

  useEffect(() => {
    setAnswers({
      [question.id]: question?.answer_data?.answer,
    });
    setFileLists({
      [question.id]: question?.answer_data?.attachments || [],
    });
    setIsEditing(
      question.answer_data === null
        ? true
        : question.answer_data.answer === null
        ? true
        : question.answer_data.answer !== null &&
          question.answer_data.answer.length < 2
        ? true
        : false
    );
    setQuestionStatus({
      [question.id]:
        question.answer_data == null
          ? null
          : question.answer_data.status != null
          ? question.answer_data.status
          : null,
    });
  }, [question]);

  const handleEditClick = () => {
    setIsEditing(true);
    setAnswers((prev) => ({
      ...prev,
      [question.id]: prev[question.id] || question.answer_data?.answer || "",
    }));
    setWarningMessages((prev) => ({ ...prev, [question.id]: "" }));
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    setWarningMessages((prev) => ({ ...prev, [question.id]: "" }));

    setUpdatedAnswers((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.question_id === question.id
      );

      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex
            ? { ...item, answer: answers[question.id] }
            : item
        );
      }

      return [
        ...prev,
        {
          answer: answers[question.id],
          question_id: question?.id,
          assessment_id: assessmentId,
          attachments: fileLists[question.id],
          status: questionStatus[question.id],
        },
      ];
    });

    if (question.answer_data?.id) {
      await assessmentApi.put(
        `/assessment/update-assessment-response/${question.answer_data?.id}`,
        {
          answer: answers[question.id],
          attachments: [...fileLists[question.id]],
        }
      );
    }
  };

  const handleUploadClick = () => {};

  const handleStatusChange = (statusValue) => {
    setQuestionStatus((prev) => ({
      ...prev,
      [question.id]: statusValue,
    }));

    setUpdatedAnswers((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.question_id === question.id
      );

      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex ? { ...item, status: statusValue } : item
        );
      }

      return [
        ...prev,
        {
          answer: answers[question.id],
          question_id: question?.id,
          assessment_id: assessmentId,
          attachments: [],
          status: statusValue,
        },
      ];
    });
  };

  const uploadProps = {
    multiple: false,
    showUploadList: false,
    accept: ".pdf,.doc,.docx,.xls,.xlsx,.csv",
    beforeUpload: async (file) => {
      if (!fileTypes.includes(file.type)) {
        // Display warning if file type is incorrect
        setWarningMessages((prev) => ({
          ...prev,
          [question.id]: "Only PDF, Word, Excel, and CSV files are allowed.",
        }));
        return Upload.LIST_IGNORE; // Prevent upload
      }

      if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
        setWarningMessages((prev) => ({
          ...prev,
          [question.id]: "File size must be less than 5 MB.",
        }));
        return Upload.LIST_IGNORE;
      }

      try {
        const formData = new FormData();
        formData.append("attachment", file);

        const savedFile = await assessmentApi.post(
          "/assessment/upload-attachment",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setUpdatedAnswers((prev) => {
          const existingIndex = prev.findIndex(
            (item) => item.question_id === question.id
          );

          if (existingIndex !== -1) {
            return prev.map((item, index) =>
              index === existingIndex
                ? {
                    ...item,
                    attachments: [...item.attachments, savedFile?.data?.data],
                  }
                : item
            );
          }

          return [
            ...prev,
            {
              attachments: [...fileLists[question.id], savedFile?.data?.data],
              question_id: question?.id,
              assessment_id: assessmentId,
            },
          ];
        });
        setFileLists((prev) => ({
          ...prev,
          [question.id]: [...(prev[question.id] || []), savedFile?.data?.data],
        }));

        if (question.answer_data?.id) {
          const answer = answers[question.id] || " ";
          await assessmentApi.put(
            `/assessment/update-assessment-response/${question.answer_data?.id}`,
            {
              answer,
              attachments: [...fileLists[question.id], savedFile?.data?.data],
            }
          );
        } else {
          await assessmentApi.post(
            "/assessment/bulk-save-assessment-response",
            [
              {
                attachments: [...fileLists[question.id], savedFile?.data?.data],
                question_id: question?.id,
                assessment_id: assessmentId,
                status: questionStatus[question.id],
              },
            ]
          );
        }

        return false;
      } catch (err) {
        return false;
      }
    },
    onRemove: async (file) => {
      await assessmentApi.delete("/assessment/delete-attachment", {
        data: { attachment_key: file },
      });

      setFileLists((prev) => ({
        ...prev,
        [question.id]: prev[question.id].filter((item) => item !== file),
      }));

      setUpdatedAnswers((prev) =>
        prev.map((item) =>
          item.question_id === question.id
            ? {
                ...item,
                attachments: item.attachments.filter(
                  (attachment) => attachment !== file
                ),
              }
            : item
        )
      );

      if (question.answer_data?.id) {
        await assessmentApi.put(
          `/assessment/update-assessment-response/${question.answer_data?.id}`,
          {
            answer: answers[question.id],
            attachments: fileLists[question.id].filter((item) => item !== file),
          }
        );
      }
    },
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between gap-4">
        <div className="flex items-center gap-5">
          <Title level={4} className="!m-0">
            {question?.question_statement}
          </Title>
          <Tooltip title={question?.additional_note}>
            <InfoCircleOutlined className="text-gray-400" />
          </Tooltip>
        </div>

        <div className="flex flex-col gap-2 items-center">
          {((!isEditing && question.answer_data !== null) ||
            answers[question.id]) && (
            <div className="w-16">
              <CustomButton
                text="Edit"
                variant="outlined"
                className="h-7 rounded-md bg-[#F4F7FF]"
                onClick={handleEditClick}
              />
            </div>
          )}
          <Select
            value={questionStatus[question.id]}
            placeholder="Select Status"
            style={{
              width: 150,
            }}
            onChange={handleStatusChange}
            options={QuestionStatus}
          />
        </div>
      </div>

      {isEditing ? (
        <div className="flex flex-col gap-2 items-end">
          <TextArea
            rows={4}
            value={answers[question.id]}
            onChange={(e) =>
              setAnswers((prev) => ({
                ...prev,
                [question.id]: e.target.value,
              }))
            }
            className="w-full"
          />
          <Button
            type="primary"
            onClick={handleSaveClick}
            className="bg-blue-500 text-white"
            disabled={answers[question.id]?.length > 0 ? false : true}
          >
            Submit
          </Button>
        </div>
      ) : (
        <Text>{answers[question.id]}</Text>
      )}

      <div className="flex flex-col gap-2">
        <div className="flex gap-2 flex-wrap">
          {fileLists[question.id]?.map((file, index) => (
            <div
              key={index}
              className="border border-appLightBlue flex items-center gap-1 px-1"
            >
              <Text className="text-xs text-appLightBlue">
                {file.name || file}
              </Text>
              <Button
                type="text"
                icon={<CloseOutlined className="!text-xs text-gray-500" />}
                onClick={() => uploadProps.onRemove(file)}
                className="h-6 !w-4"
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-1">
          <Upload {...uploadProps}>
            <Button
              variant="outlined"
              className={`text-gray-500 border border-gray-400 bg-[#F4F7FF] h-6 px-2`}
              onClick={handleUploadClick}
            >
              Add Attachment <PlusCircleOutlined />
            </Button>
          </Upload>
          {warningMessages[question.id] && (
            <Text type="danger" className="text-red-500">
              {warningMessages[question.id]}
            </Text>
          )}
        </div>
      </div>

      <Divider className="mt-0" />
    </div>
  );
};

export default Questions;
