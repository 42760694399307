import React, { useState } from "react";

import Background from "../components/Background";
import SignInForm from "../components/SignInForm";
import SignInVerify from "../components/SignInVerify";
import SignInCode from "../components/SignInCode";

const SignIn = () => {
  const [step, setStep] = useState(1);

  // Function to handle next step
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <Background>
      {step === 1 && <SignInForm handleNextStep={handleNextStep} />}
      {step === 2 && <SignInVerify handleNextStep={handleNextStep} />}
      {step === 3 && <SignInCode />}
    </Background>
  );
};

export default SignIn;
