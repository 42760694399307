import React, { useEffect, useState, useContext } from "react";
import { Typography, Spin, Card, Avatar, Divider, Input } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import TokenExpireModal from "../components/TokenExpireModal";
import { AuthContext } from "../context/AuthContext";
import { UserOutlined } from "@ant-design/icons";
import { FormOutlined } from "@ant-design/icons";
import CustomButton from "../components/CustomButton";
import ChangePassword from "./ChangePassword";
import defaultPic from "../assets/defaultPic.jpg"
const { Title } = Typography;

const ProfileDetail = () => {
 
  const { user } = useContext(AuthContext);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
        useState(false);
    const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
    const [isEditAble, setIsEditAble] = useState(false)
    const [image, setImage] = useState("");
    const [value, setValue] = useState(user?.data?.user?.name)

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center"></div>
      <Title className="px-5 pt-5" level={3}>
        Profile
      </Title>
      <Card className="h-[20vh] mt-5 ml-5 mr-5 bg-white px-5 pt-5">
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 flex flex-row gap-3 items-center">
                      <div><Avatar size="large" src={defaultPic}/></div>
            <div className="text-[17px] font-semibold">
              {user?.data?.user?.name}
            </div>
          </div>
          <div className="flex flex-row gap-3 w-72 items-center">
            <CustomButton
              text="Change Password"
              variant="outlined"
              className="h-11"
              onClick={() => setIsChangePasswordModalOpen(true)}
            />
            <CustomButton
              text={
                              <div
                                //   onClick={() => setIsEditAble(true)}
                              >
                  <FormOutlined style={{ marginRight: 8 }} />
                  Edit
                </div>
              }
              type="primary"
              htmlType="submit"
              className="h-11"
            />
          </div>
        </div>
      </Card>
      <Card className="mr-5 ml-5 px-5 pt-5 h-[20vh]">
        <div className="w-full flex flex-row justify-between items-center gap-4">
          <div className="w-1/2">
            <p className="mb-4 text-[15px] font-semibold">Full Name</p>
            {isEditAble === true ? (
              <Input
                type="text"
                name="name"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            ) : (
              <div className="text-gray-500 border-b border-gray-300">
                {user?.data?.user?.name}
              </div>
            )}
          </div>
          <div className="w-1/2">
            <p className="mb-4 text-[15px] font-semibold">Email</p>
            <div className="text-gray-500 border-b border-gray-300">
              {user?.data?.user?.email}
            </div>
          </div>
        </div>
      </Card>
      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
      <ChangePassword
        isChangePasswordModalOpen={isChangePasswordModalOpen}
        setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
        email={user?.data?.user?.email}
      />
    </DashboardBackground>
  );
};

export default ProfileDetail;
