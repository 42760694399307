import React from "react";
import { Typography } from "antd";
import CustomButton from "../CustomButton";
import { ApiOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const Introduction = ({ introduction }) => {
  return (
    <>
      <div className="flex justify-between">
        <Title level={2}>Introduction</Title>
      </div>

      <div className="flex flex-col gap-8 h-[200px] min-[1060px]:h-[250px] min-[1535px]:h-[370px] px-3 overflow-y-scroll">
        <div>
          <Title level={4}>About Standards</Title>
          <Text>{introduction?.summary}</Text>
        </div>

        <div>
          <Title level={4}>Key Points</Title>
          <ul className="list-disc ml-5">
            {introduction?.key_points?.map((point) => (
              <li>
                <Text>{point}</Text>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <Title level={4}>Quick Links</Title>
          <div className="flex gap-4">
            {introduction?.quick_links?.map((link, index) => (
              <div
                className="flex flex-col gap-3 py-4 px-9 border-2 w-fit cursor-pointer"
                onClick={() =>
                  window.open(link, "_blank", "noopener,noreferrer")
                }
              >
                <ApiOutlined className="text-4xl text-appLightBlue" />
                <Text>Link {index + 1}</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
