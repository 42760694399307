import React, { useEffect, useState, useContext } from "react";
import { Table, Typography, Spin, Tag } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import CustomSearch from "../components/CustomSearch";
import CustomButton from "../components/CustomButton";
import { notificationsContext } from "../context/Notifications";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import AssignModal from "../components/Remediation/AssignModal";
import EvidenceModal from "../components/Remediation/EvidenceModal";

const { Title } = Typography;

const Remediation = () => {
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
      const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const navigate = useNavigate();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    (async () => await fetchData())();
  }, [refreshTable, searchText]);

  const fetchData = async (currentPage = 1, pageSize = 10) => {
   
  };
  const handleTableChange = async (pagination) => {
    setTableParams({
      pagination,
    });
    await fetchData(pagination.current, pagination.pageSize);
  };
  const data = [
      {
        id:1,
      question: "question",
      answer: "answer",
      missing_control: "missing control",
      evidence: "evidence 1",
      status: "Resolved",
      remediation_severity: "Low",
      assign: null,
      assigned_date: "DD/MM/YYYY",
      due_date: "DD/MM/YYYY",
    },
    { id:2,
      question: "question",
      answer: "answer",
      missing_control: "missing control",
      evidence: "evidence 2",
      status: "In Progress",
      remediation_severity: "Medium",
      assign: "Kierra Herwitz",
      assigned_date: "DD/MM/YYYY",
      due_date: "DD/MM/YYYY",
    },
      {
        id:3,
      question: "question",
      answer: "answer",
      missing_control: "missing control",
      evidence: "evidence 3",
      status: "Resolved",
      remediation_severity: "High",
      assign: null,
      assigned_date: "DD/MM/YYYY",
      due_date: "DD/MM/YYYY",
    },
  ];

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Missing Control",
      dataIndex: "missing_control",
      key: "missing_control",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Evidence",
      dataIndex: "evidence",
      key: "evidence",
      render: (text) => (
        <span className="capitalize text-cyan-500" onClick={()=> setIsEvidenceModalOpen(true)}>{text}</span>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => {
        let color = "";

        status === "In Progress" ? (color = "gold") : (color = "green");

        return (
          <Tag
            color={color}
            key={status}
            className="rounded-2xl font-semibold text-sm"
            bordered={false}
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Remediation Severity",
      dataIndex: "remediation_severity",
      key: "remediation_severity",
      render: (text) => <span className="capitalize ">{text}</span>,
      align: "center",
    },
    {
      title: "Assign",
      dataIndex: "assign",
      key: "assign",
      render: (_, record) => {
        return record.assign === null ? (
          <a
            className="capitalize text-cyan-500"
            onClick={() => setIsAssignModalOpen(true)}
          >
            Assign
          </a>
        ) : (
          <span className="capitalize">{record.assign}</span>
        );
      },
    },
    {
      title: "Assigned Date",
      dataIndex: "assigned_date",
      key: "assigned_date",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Actions",
      key: "action",

      render: (_, record) => (
        <div className="w-full flex justify-center">
          <div className="w-14">
            <CustomButton
              text="View"
              variant="outlined"
              className="h-7"
            />
          </div>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center">
        <CustomSearch setSearchText={setSearchText} />
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5 overflow-scroll overflow-x-hidden">
        <Title level={3}>Remediation</Title>
        <div className="mt-6">
          {isLoading ? (
            <Spin className="flex justify-center items-center" />
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading}
              pagination={{
                ...tableParams.pagination,
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          )}
        </div>
      </div>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
      <AssignModal
        isAssignModalOpen={isAssignModalOpen}
        setIsAssignModalOpen={setIsAssignModalOpen}
        setRefreshTable={setRefreshTable}
          />
          <EvidenceModal
              isEvidenceModalOpen={isEvidenceModalOpen}
              setIsEvidenceModalOpen={setIsEvidenceModalOpen}
          setRefreshTable={setRefreshTable}/>
    </DashboardBackground>
  );
};
export default Remediation;
