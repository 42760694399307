import React from "react";
import { Spin, Typography } from "antd";
import CustomButton from "../CustomButton";
import Questions from "./Questions";

const { Title } = Typography;

const QuestionsWrapper = ({
  name,
  data,
  setUpdatedAnswers,
  handleSaveQuestionnaire,
  assessmentId,
  isSaving,
  stepsCount,
  currentStep,
}) => {
  return (
    <>
      <div className="flex justify-between">
        <Title level={2}>{name}</Title>

        {currentStep > 0 && (
          <div className="flex gap-3">
            <CustomButton
              text={isSaving ? <Spin /> : "Save Assessment"}
              type="primary"
              className="h-10 bg-appLightBlue"
              onClick={handleSaveQuestionnaire}
              disabled={isSaving}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col h-[200px] min-[1060px]:h-[250px] min-[1535px]:h-[370px] w-full px-3 mt-2 overflow-y-scroll">
        {data.map((question) => (
          <Questions
            question={question}
            setUpdatedAnswers={setUpdatedAnswers}
            assessmentId={assessmentId}
          />
        ))}
      </div>
    </>
  );
};

export default QuestionsWrapper;
