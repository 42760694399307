import React from "react";
import {Modal, Typography } from "antd";
import CustomButton from "../CustomButton";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const AssessmentSubmitModal = ({
  isModalOpen,
  setIsModalOpen,
  title = "Default Title", // default title if none is provided
  icon = <CheckCircleOutlined className="text-green-500 text-2xl" />, // default icon
  bodyText = "Default body text", // default body text
  buttons = [
    {
      text: "Close",
      type: "primary",
      onClick: () => setIsModalOpen(false), // default close function
    },
  ], // default button configuration
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={
        <div className="flex gap-5 items-center mt-3">
          {icon}
          <Title level={5} className="!m-0">
            {title}
          </Title>
        </div>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      centered
      footer={null}
      width={500}
    >
      <div className="my-3 ml-11">
        <Text>{bodyText}</Text>
      </div>

      <div className="flex w-full justify-end gap-2">
        {buttons.map((button, index) => (
          <div key={index} className="w-24">
            <CustomButton
              text={button.text}
              type={button.type}
              className="h-8 my-3"
              onClick={button.onClick || handleCancel}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default AssessmentSubmitModal;
