import React, { useContext, useState } from "react";
import { Typography, Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import api from "../api/axios";
import { AuthContext } from "../context/AuthContext";
import { notificationsContext } from "../context/Notifications";

const { Title, Text, Link } = Typography;

const SignInForm = ({ handleNextStep }) => {
  const { Item } = Form;
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { api: notificationAPI } = useContext(notificationsContext);

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const res = await api.post("/login", values);
      login(res?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };

  return (
    <CustomCard>
      <Title level={2} className="!font-bold">
        Sign In
      </Title>

      <Form onFinish={onFinish} layout="vertical">
        <Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    value
                  )
                ) {
                  return Promise.reject(new Error("The email is not valid!"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="test@test.com" size="large" />
        </Item>

        <Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password placeholder="Enter your password" size="large" />
        </Item>

        <Item>
          <CustomButton
            text={isLoading ? <Spin /> : "Sign In"}
            type="primary"
            htmlType="submit"
            disabled={isLoading}
          />
        </Item>
      </Form>

      <div className="w-full flex justify-between">
        <Link
          className="!underline !text-appBlue"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password?
        </Link>
        <div className="flex gap-1">
          <Text type="secondary">Already have an account?</Text>
          <Link
            className="!underline !text-appBlue"
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </CustomCard>
  );
};

export default SignInForm;
